import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/pageHeader';
import GetHelpSection from '../components/getHelpSection';
import { ContentContainer, Sidebar, Main } from '../components/container';

const FloodInfoTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.markdownRemark.frontmatter.title}
      description={data.markdownRemark.frontmatter.subtitle}
    />
    <PageHeader
      title={data.markdownRemark.frontmatter.title}
      subtitle={data.markdownRemark.frontmatter.subtitle}
    />
    <ContentContainer>
      <Sidebar>
        <nav
          role="navigation"
          aria-label="table of contents"
          dangerouslySetInnerHTML={{
            __html: data.markdownRemark.tableOfContents,
          }}
        />
      </Sidebar>
      <Main>
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </Main>
    </ContentContainer>
    <GetHelpSection />
  </Layout>
);

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      tableOfContents
      frontmatter {
        title
        subtitle
      }
    }
  }
`;
export default FloodInfoTemplate;
